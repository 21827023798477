
















































































































































































































































































































































































.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
// .el-table--border::after, .el-table--group::after{
//   height: 0;
// }
// .el-table--enable-row-transition .el-table__body td:first-child{
//   border-left: 1px solid #EBEEF5;
// }
// .el-table--border, .el-table--group{
//   border: 0;
// }
